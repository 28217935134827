import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Typography, Paper, Button } from '@mui/material';

const SuccessPage = () => {
  const location = useLocation();
  const reportData = location.state?.reportData;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const stripTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const downloadCSV = () => {
    if (!reportData) return;

    const csvContent = Object.entries(reportData)
      .map(([key, value]) => `${key},${typeof value === 'string' ? stripTags(value).replace(/,/g, ';') : value}`)
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'report_summary.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (!reportData) {
    return <Typography>No report data available.</Typography>;
  }

  return (
    <div className='form-style'>
    <Container maxWidth="md" className="mt-5">
      <Paper elevation={3} className="p-4">
        <Typography variant="h4" gutterBottom>Report Submitted Successfully!</Typography>
        <Typography variant="h6" gutterBottom>Summary of your report:</Typography>
        {Object.entries(reportData).map(([key, value]) => {
          if (key === 'id' || key === 'user') return null; // Skip these fields
          let displayValue = value;
          if (typeof value === 'string' && (key.includes('calendar') || key.includes('church') || key.includes('allocations') || key.includes('achievements') || key.includes('challenges') || key.includes('resolution'))) {
            displayValue = stripTags(value);
          }
          if (key === 'submitted_at') {
            displayValue = formatDate(value);
          }
          return (
            <Typography key={key}>
              <strong>{key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}:</strong> {displayValue}
            </Typography>
          );
        })}
        <Button variant="contained" color="primary" onClick={downloadCSV} className="mt-3 me-2">
          Download CSV
        </Button>
        <Button variant="contained" color="secondary" component={Link} to="/" className="mt-3">
          Back to Home
        </Button>
      </Paper>
    </Container>
    </div>
  );
};

export default SuccessPage;