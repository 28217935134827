import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Select, MenuItem, Button, Typography, Box } from '@mui/material';
import { register } from '../services/auth';
import './Styles.css'

const validationSchema = Yup.object().shape({
  full_name: Yup.string().required('Required'),
  role: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(8, 'Password must be at least 8 characters').required('Required'),
  repeat_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

const SignUp = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await register(values);
      navigate('/login');
    } catch (error) {
      if (error.response && error.response.data) {
        setError(JSON.stringify(error.response.data));
      } else {
        setError('Registration failed. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='form-style'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 400,
          margin: 'auto',
          marginTop: 8,
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>Sign Up</Typography>
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
        <Formik
          initialValues={{
            full_name: '',
            role: '',
            username: '',
            email: '',
            password: '',
            repeat_password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form style={{ width: '100%' }}>
              <Field
                name="full_name"
                as={TextField}
                label="Full Name"
                fullWidth
                margin="normal"
                error={touched.full_name && errors.full_name}
                helperText={touched.full_name && errors.full_name}
              />

              <label>Select your title:</label>
              <Field
                name="role"
                as={Select}
                label="Select Title "
                fullWidth
                margin="normal"
                error={touched.role && errors.role}
              >
                <MenuItem value="">Select Role</MenuItem>
                <MenuItem value="Senior Deputy Archbishop">Senior Deputy Archbishop</MenuItem>
                <MenuItem value="Deputy Archbishop">Deputy Archbishop</MenuItem>
                <MenuItem value="Snr Bishop">Snr Bishop</MenuItem>
                <MenuItem value="Bishop">Bishop</MenuItem>
                <MenuItem value="Regional Overseer">Regional Overseer</MenuItem>
                <MenuItem value="Overseer">Overseer</MenuItem>
                <MenuItem value="Senior Pastor">Senior Pastor</MenuItem>
                <MenuItem value="Pastor">Pastor</MenuItem>
                <MenuItem value="Leader">Leader</MenuItem>
              </Field>
              <Field
                name="username"
                as={TextField}
                label="Preferred Username"
                fullWidth
                margin="normal"
                error={touched.username && errors.username}
                helperText={touched.username && errors.username}
              />
              <Field
                name="email"
                as={TextField}
                label="Email"
                fullWidth
                margin="normal"
                error={touched.email && errors.email}
                helperText={touched.email && errors.email}
              />
              <Field
                name="password"
                as={TextField}
                label="Password"
                type="password"
                fullWidth
                margin="normal"
                error={touched.password && errors.password}
                helperText={touched.password && errors.password}
              />
              <Field
                name="repeat_password"
                as={TextField}
                label="Repeat Password"
                type="password"
                fullWidth
                margin="normal"
                error={touched.repeat_password && errors.repeat_password}
                helperText={touched.repeat_password && errors.repeat_password}
              />
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2, mb: 2 }}>
                Sign Up
              </Button>
            </Form>
          )}
        </Formik>
        <Typography>
          Already have an account? <Link to="/login" className='text-primary'>Login</Link>
        </Typography>
      </Box>
    </div>
  );
};

export default SignUp;