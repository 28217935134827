import axios from 'axios';

const API_URL = 'https://backend.bishopsdesktransnzoia.info/api/';

export const login = async (username, password) => {
  try {
    const response = await axios.post(API_URL + 'token/', { username, password });
    if (response.data.access) {
      localStorage.setItem('token', response.data.access);
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const register = async (userData) => {
  try {
    const response = await axios.post(API_URL + 'register/', userData);
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const getCurrentUser = () => {
  return localStorage.getItem('token');
};

export const resetPassword = async (email) => {
  try {
    const response = await axios.post(API_URL + 'password-reset/', { email });
    console.log('Reset password response:', response);
    return { message: response.data.message || 'Password reset email sent successfully.' };
  } catch (error) {
    console.error('Password reset request error:', error);
    console.error('Error response:', error.response);
    throw error;
  }
};

export const setNewPassword = async (uid, token, new_password) => {
  try {
    const response = await axios.post(API_URL + 'password-reset-confirm/', {
      uid,
      token,
      new_password
    });
    return { message: response.data.message || 'Password reset successfully.' };
  } catch (error) {
    console.error('Set new password error:', error);
    throw error;
  }
};