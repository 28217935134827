import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import './components/Styles.css';
import RH from './images/RH.png';
import backgroundVideo from './images/video.mp4';
import SubmitReport from './components/SubmitReport';
import Login from './components/Login';
import SignUp from './components/SignUp';
import PasswordReset from './components/PasswordReset';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import { getCurrentUser, logout } from './services/auth';
import ErrorPage from './components/ErrorPage';
import SuccessPage from './components/SuccessPage';

function Layout({ isAuthenticated, setIsAuthenticated }) {
  const location = useLocation();

  const handleLogout = () => {
    logout();
    setIsAuthenticated(false);
  };

  return (
    <div className="App d-flex flex-column min-vh-100">
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: '-1',
        }}
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-custom-blue">
          <div className="container-fluid">
            <img className="rh" src={RH} alt="logo" />
            <Link className="navbar-brand text-light" to="/">
              Trans-Nzoia County
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <hr></hr>
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link className={`nav-link text-white ${location.pathname === '/' ? 'active' : ''}`} to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link text-white ${location.pathname === '/report' ? 'active' : ''}`} to="/report">
                    Monthly Report
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link text-white ${location.pathname === '/about' ? 'active' : ''}`} to="/about">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link text-white ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact">
                    Contact
                  </Link>
                </li>
                {!isAuthenticated ? (
                  <>                    
                    <li className="nav-item">
                      <Link className={`nav-link text-white ${location.pathname === '/signup' ? 'active' : ''}`} to="/signup">
                        Sign Up
                      </Link>
                    </li>
                  </>
                ) : (
                  <li className="nav-item">
                    <button className="nav-link btn btn-link text-white" onClick={handleLogout}>
                      Logout
                    </button>
                  </li>
                )}
                <li className="nav-item">
                  <a href='https://backend.bishopsdesktransnzoia.info/admin/' className="nav-link text-white" target="_blank" rel="noopener noreferrer">
                    admin
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <main className="flex-grow-1 d-flex flex-column">
        <div className="container flex-grow-1" style={{ position: 'relative', zIndex: '1' }}>
          <Outlet />
        </div>
      </main>

      <footer className="bg-custom-dark text-center text-lg-start text-light mt-auto">
        <div className="container p-4">
          <div className="row">
            <div className="col-lg-6  mb-md-0 ">
              <h5 className="text-uppercase small-screen">Ministry Of Repentance and Holiness</h5>
              <p className='style-cyan'>
                Trans-Nzoia County Bishops Counsels Monthly Reports
              </p>
            </div>
            
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0 hidden-small">
              <h5 className="text-uppercase small-font">Links</h5>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/about" className="style-cyan text-decoration-none">About Us</Link>
                </li>
                <li>
                  <Link to="/contact" className="style-cyan text-decoration-none">Contact</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0 hidden-small">
              <h5 className="text-uppercase mb-0 small-font">Follow Us</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="https://www.facebook.com/watch/jesusiscomingofficial/" className="style-cyan text-decoration-none" target="_blank" rel="noopener noreferrer">Facebook</a>
                </li>
                <li>
                  <a href="https://x.com/jesusiscoming_2" className="style-cyan text-decoration-none" target="_blank" rel="noopener noreferrer">Twitter</a>
                </li>
                <li>
                  <a href="https://www.instagram.com/jesusiscoming_2/" className="style-cyan text-decoration-none" target="_blank" rel="noopener noreferrer">Instagram</a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@repentandpreparethewa" className="style-cyan text-decoration-none" target="_blank" rel="noopener noreferrer">YouTube</a>
                </li>
              </ul>
            </div>

            <div className='links-styling'>
              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase small-font">Links</h5>
                <ul className="list-unstyled mb-0">
                  <li>
                    <Link to="/about" className="style-cyan text-decoration-none">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact" className="style-cyan text-decoration-none">Contact</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase mb-0 small-font">Follow Us</h5>
                <ul className="list-unstyled">
                  <li>
                    <a href="https://www.facebook.com/watch/jesusiscomingofficial/" className="style-cyan text-decoration-none" target="_blank" rel="noopener noreferrer">Facebook</a>
                  </li>
                  <li>
                    <a href="https://x.com/jesusiscoming_2" className="style-cyan text-decoration-none" target="_blank" rel="noopener noreferrer">Twitter</a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/jesusiscoming_2/" className="style-cyan text-decoration-none" target="_blank" rel="noopener noreferrer">Instagram</a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@repentandpreparethewa" className="style-cyan text-decoration-none" target="_blank" rel="noopener noreferrer">YouTube</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <p>Listen to the Jesus Is Lord Radio</p>
          <div className="audio-player text-center">
            <audio controls>
              <source src="https://s3.radio.co/s97f38db97/listen" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
        <hr />
        <div className="text-center p-3 text-light">
          © 2024 Trans-Nzoia County Bishops Desk. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const user = getCurrentUser();
    setIsAuthenticated(!!user);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="login" element={isAuthenticated ? <Navigate to="/" replace /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="signup" element={isAuthenticated ? <Navigate to="/" replace /> : <SignUp />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="reset-password/:uid/:token" element={<PasswordReset />} />
          <Route
            path="report"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <SubmitReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="success"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <SuccessPage />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<ErrorPage error={{ status: 404 }} />} />
        </Route>
      </Routes>
    </Router>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={{ status: 500 }} />;
    }

    return this.props.children; 
  }
}

function AppWithErrorBoundary() {
  return (
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  );
}

export default AppWithErrorBoundary;
