import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReportForm from './ReportForm';
import { Alert } from '@mui/material';

const SubmitReport = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post('https://backend.bishopsdesktransnzoia.info/api/reports/', values, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      navigate('/success', { state: { reportData: response.data } });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
      } else {
        setError('An error occurred. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <h1 className='text-center mt-5 style-for-me'>Submit Monthly Report</h1>
      {error && <Alert severity="error">{error}</Alert>}
      <ReportForm onSubmit={handleSubmit} />
    </div>
  );
};

export default SubmitReport;