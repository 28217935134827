import React from 'react';
import { Container, Typography, Box, Card, CardContent, Grid } from '@mui/material';

const About = () => {
  return (
    <div className='form-style'>
      <Container maxWidth="lg" style={{ padding: '2rem' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          This is the Ministryof Repentance And Holiness, Trans Nzoia County Bishops Counsel. <br></br>
          <i><b>For more information contact Snr Debuty ArchBishop Right Reverend Silas Bundotich: +254 721 309221</b></i>
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Every month, our dedicated team compiles reports that document our activities, achievements, and challenges. 
          Blessed people make sure the reports are accurate when submitting.
        </Typography>
        <Box mt={5}>
          <Typography variant="h4" component="h2" gutterBottom>
            Our Vision and Mission
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography className='bg-custom-blue-home' variant="h5" component="h3">
                    Mission
                  </Typography>
                  <Typography variant="body1" component="p">
                    Prepare The Way
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography className='bg-custom-blue-home' variant="h5" component="h3">
                    Vision
                  </Typography>
                  <Typography variant="body1" component="p">
                    The Messiah Is Coming
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default About;
