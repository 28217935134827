import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { TextField, Button, Typography, Box } from '@mui/material';

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://backend.bishopsdesktransnzoia.info/api/token/', { username, password });
      localStorage.setItem('token', response.data.access);
      setIsAuthenticated(true);
      navigate('/report');
    } catch (error) {
      setError('Invalid credentials');
    }
  };

  return (
    <div className='form-style'>
    <Box
      component="form"
      onSubmit={handleLogin}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 400,
        margin: 'auto',
        marginTop: 8,
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>Login</Typography>
      {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
      <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mb: 2 }}>
        Login
      </Button>
      <Typography>
        <Link to="/password-reset" className='text-decoration-none text-primary'>Forgot password?</Link>
      </Typography>
      <Typography>
        Don't have an account? <Link to="/signup" className=' text-decoration-none text-primary'>Sign up</Link>
      </Typography>
    </Box>
    </div>
  );
};

export default Login;