import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Select, MenuItem, Button } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Styles.css'

const validationSchema = Yup.object().shape({
  full_name: Yup.string().required('Required'),
  phone: Yup.string().required('Required').matches(/^[0-9]+$/, "Must be only digits"),
  title: Yup.string().required('Required'),
  region: Yup.string().required('Required'),
  monthly_revival_calendar: Yup.string().required('Required'),
  quarterly_revival_calendar: Yup.string().required('Required'),
  crusades_done: Yup.number().required('Required').min(0, 'Must be a positive number'),
  state_of_church: Yup.string().required('Required'),
  membership_attendance: Yup.number().required('Required').min(0, 'Must be a positive number'),
  monthly_bills_allocations: Yup.string().required('Required'),
  achievements: Yup.string().required('Required'),
  challenges: Yup.string().required('Required'),
  resolution: Yup.string().required('Required'),
});

const ReportForm = ({ onSubmit }) => {
  return (
    <div className='form-style'>
      <Formik
        initialValues={{
          full_name: '',
          phone: '',
          title: '',
          region: '',
          monthly_revival_calendar: '',
          quarterly_revival_calendar: '',
          crusades_done: 0,
          state_of_church: '',
          membership_attendance: 0,
          monthly_bills_allocations: '',
          achievements: '',
          challenges: '',
          resolution: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            <Field
              name="full_name"
              as={TextField}
              label="Full Name"
              fullWidth
              margin="normal"
              error={touched.full_name && errors.full_name}
              helperText={touched.full_name && errors.full_name}
              style={{ marginBottom: '20px' }}
            />
            <Field
              name="phone"
              as={TextField}
              label="Contact Phone"
              fullWidth
              margin="normal"
              error={touched.phone && errors.phone}
              helperText={touched.phone && errors.phone}
              style={{ marginBottom: '20px' }}
            />

            <label>Select your title</label>
            <Field
              name="title"
              as={Select}
              label="Title"
              fullWidth
              margin="normal"
              error={touched.title && errors.title}
              style={{ marginBottom: '20px' }}
            >
              <MenuItem value="">Select Title</MenuItem>
              <MenuItem value="Senior Deputy Archbishop">Senior Deputy Archbishop</MenuItem>
              <MenuItem value="Deputy Archbishop">Deputy Archbishop</MenuItem>
              <MenuItem value="Snr Bishop">Snr Bishop</MenuItem>
              <MenuItem value="Bishop">Bishop</MenuItem>
              <MenuItem value="Regional Overseer">Regional Overseer</MenuItem>
              <MenuItem value="Overseer">Overseer</MenuItem>
              <MenuItem value="Senior Pastor">Senior Pastor</MenuItem>
              <MenuItem value="Pastor">Pastor</MenuItem>
              <MenuItem value="Leader">Leader</MenuItem>
            </Field>

            <label>Select your region</label>
            <Field
              name="region"
              as={Select}
              label="Region"
              fullWidth
              margin="normal"
              error={touched.region && errors.region}
              style={{ marginBottom: '20px' }}
            >
              <MenuItem value="">Select Region</MenuItem>
              <MenuItem value="Transznoia Main">Transznoia Main</MenuItem>
              <MenuItem value="Kitale Central">Kitale Central</MenuItem>
              <MenuItem value="Kitale East">Kitale East</MenuItem>
              <MenuItem value="Kitale West">Kitale West</MenuItem>
              <MenuItem value="Kiminini Central">Kiminini Central</MenuItem>
              <MenuItem value="Kiminini West">Kiminini West</MenuItem>
              <MenuItem value="Saboti West">Saboti West</MenuItem>
              <MenuItem value="Saboti East">Saboti East</MenuItem>
              <MenuItem value="Endebess West">Endebess West</MenuItem>
              <MenuItem value="Endebess East">Endebess East</MenuItem>
              <MenuItem value="Endebess Central">Endebess Central</MenuItem>
              <MenuItem value="Kwanza West">Kwanza West</MenuItem>
              <MenuItem value="Kwanza East">Kwanza East</MenuItem>
              <MenuItem value="Kwanza Central">Kwanza Central</MenuItem>
              <MenuItem value="Cherangany East">Cherangany East</MenuItem>
              <MenuItem value="Cherangany West">Cherangany West</MenuItem>
            </Field>

            <label>Monthly Revival Calendar</label>
            <ReactQuill
              theme="snow"
              value={values.monthly_revival_calendar}
              onChange={(value) => setFieldValue('monthly_revival_calendar', value)}
              style={{ height: '200px', marginBottom: '20px' }}
            />

            <label className='mt-5'>Quarterly Revival Calendar</label>
            <ReactQuill
              theme="snow"
              value={values.quarterly_revival_calendar}
              onChange={(value) => setFieldValue('quarterly_revival_calendar', value)}
              style={{ height: '200px', marginBottom: '20px' }}
            />

            <label className='mt-5'>Number of Crusades Done</label>
            <Field
              name="crusades_done"
              as={TextField}
              label="Number of Crusades Done"
              type="number"
              fullWidth
              margin="normal"
              error={touched.crusades_done && errors.crusades_done}
              helperText={touched.crusades_done && errors.crusades_done}
              style={{ marginBottom: '20px' }}
            />

            <label>State of the Church</label>
            <ReactQuill
              theme="snow"
              value={values.state_of_church}
              onChange={(value) => setFieldValue('state_of_church', value)}
              style={{ height: '200px', marginBottom: '20px' }}
            />

            <label className='mt-5'>Membership/Attendance</label>
            <Field
              name="membership_attendance"
              as={TextField}
              label="Membership/Attendance"
              type="number"
              fullWidth
              margin="normal"
              error={touched.membership_attendance && errors.membership_attendance}
              helperText={touched.membership_attendance && errors.membership_attendance}
              style={{ marginBottom: '20px' }}
            />

            <label>Monthly Bills and Allocations</label>
            <ReactQuill
              theme="snow"
              value={values.monthly_bills_allocations}
              onChange={(value) => setFieldValue('monthly_bills_allocations', value)}
              style={{ height: '200px', marginBottom: '20px' }}
            />

            <label className='mt-5 margin-t'>Achievements</label>
            <ReactQuill
              theme="snow"
              value={values.achievements}
              onChange={(value) => setFieldValue('achievements', value)}
              style={{ height: '200px', marginBottom: '20px' }}
            />

            <label className='mt-5 margin-t'>Challenges</label>
            <ReactQuill
              theme="snow"
              value={values.challenges}
              onChange={(value) => setFieldValue('challenges', value)}
              style={{ height: '200px', marginBottom: '20px' }}
            />

            <label className='mt-5 margin-t'>Resolution</label>
            <ReactQuill
              theme="snow"
              value={values.resolution}
              onChange={(value) => setFieldValue('resolution', value)}
              style={{ height: '200px', marginBottom: '20px' }}
            />
            <div className='mt-5'></div>
            <Button className='mt-5 ' type="submit" variant="contained" color="primary">
              Submit Report
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReportForm;
