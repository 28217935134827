import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Alert } from '@mui/material';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://backend.bishopsdesktransnzoia.info/api/contact/', formData);
      if (response.status === 201) {
        setSuccessMessage('Your message has been sent successfully!');
        setErrorMessage('');
        setFormData({ name: '', email: '', subject: '', message: '' });
      }
    } catch (error) {
      setErrorMessage('Failed to send your message. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <div className='form-style'>
    <Container maxWidth="lg" style={{ padding: '2rem' }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" component="p" paragraph>
        Have any questions or need more information? Feel free to reach out to us. We're here to help!
      </Typography>
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Box component="form" noValidate autoComplete="off" mt={3} onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          fullWidth
          margin="normal"
          variant="outlined"
          required
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          fullWidth
          margin="normal"
          variant="outlined"
          required
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          label="Subject"
          name="subject"
          fullWidth
          margin="normal"
          variant="outlined"
          required
          value={formData.subject}
          onChange={handleChange}
        />
        <TextField
          label="Message"
          name="message"
          fullWidth
          margin="normal"
          variant="outlined"
          multiline
          rows={4}
          required
          value={formData.message}
          onChange={handleChange}
        />
        <Button variant="contained" color="primary" type="submit" style={{ marginTop: '1rem' }}>
          Send Message
        </Button>
      </Box>
    </Container>
    </div>
  );
};

export default Contact;
