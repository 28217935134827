import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Typography, Box } from '@mui/material';
import { resetPassword, setNewPassword } from '../services/auth';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [new_password, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [resetRequested, setResetRequested] = useState(false);
  const navigate = useNavigate();
  const { uid, token } = useParams();

  useEffect(() => {
    if (uid && token) {
      console.log("Reset password params:", { uid, token });
    }
  }, [uid, token]);

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      const response = await resetPassword(email);
      setMessage(response?.message || 'Password reset email sent successfully.');
      setResetRequested(true);
    } catch (error) {
      console.error('Password reset request error:', error);
      setError(error.response?.data?.detail || 'Password reset failed. Please try again.');
    }
  };

  const handleSetNewPassword = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    
    if (new_password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await setNewPassword(uid, token, new_password);
      setMessage(response?.message || 'Password reset successfully.');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      console.error('Set new password error:', error);
      if (error.response) {
        setError(error.response.data?.detail || 'Failed to set new password. Please try again.');
      } else if (error.request) {
        setError('No response received from server. Please try again.');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  if (uid && token) {
    return (
      <div className='form-style'>
        <Box component="form" onSubmit={handleSetNewPassword} sx={{ textAlign: 'center' }}>
          <Typography variant="h5" sx={{ mb: 2 }}>Set New Password</Typography>
          {message && <Typography color="success" sx={{ mb: 2 }}>{message}</Typography>}
          {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
          <TextField
            label="New Password"
            type="password"
            value={new_password}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Confirm New Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, mb: 2 }}>
            Set New Password
          </Button>
        </Box>
      </div>
    );
  }

  return (
    <div className='form-style'>
      <Box component="form" onSubmit={handleRequestReset} sx={{ textAlign: 'center' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>Reset Password</Typography>
        {message && <Typography color="success" sx={{ mb: 2 }}>{message}</Typography>}
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
        {!resetRequested ? (
          <>
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <Button type="submit" variant="contained" color="secondary" sx={{ mt: 2, mb: 2 }}>
              Request Password Reset
            </Button><br></br>
          </>
        ) : (
          <Typography>
            A password reset link has been sent to your email. Please check your inbox and click the link to reset your password.
          </Typography>
        )}
        <Button onClick={() => navigate('/login')} variant="contained" color="primary">
          Back to Login
        </Button>
      </Box>
    </div>
  );
};

export default PasswordReset;