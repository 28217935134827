import React from 'react';
import { Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className='form-style'>
    <Container
      maxWidth="lg"
      style={{
        fontWeight: '600',
        textAlign: 'center',
        padding: '2rem',
        position: 'relative',
        zIndex: '1',
        color: '#000', // Ensure text is readable
      }}
    >
      <Box>
        <h1 className='home-style '>
          Welcome to Trans Nzoia County Bishop's Desk
          </h1>
        {/* <Typography className='text-dark' variant="h5" component="p" gutterBottom>
          Make sure you have submitted your Monthly reports
        </Typography> */}
         <Link className='home-btn-style' to="/report"> Submit Monthly Report </Link> 
        
      </Box>
    
      
    </Container>
    
    </div>
  );
};

export default Home;
