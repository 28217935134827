import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = ({ error }) => {
  let errorMessage = "An unexpected error has occurred.";
  let errorCode = "Oops!";

  if (error?.status === 404) {
    errorCode = '404';
    errorMessage = "We're sorry, the page you're looking for could not be found.";
  } else if (error?.status === 500) {
    errorCode = '500';
    errorMessage = "We're experiencing some internal server issues. Please try again later.";
  }

  return (
    <div className='form-style'>
    <div className="error-page d-flex align-items-center justify-content-center" style={{ minHeight: '60vh' }}>
      <div className="text-center">
        <h1 className="display-1 fw-bold text-primary">{errorCode}</h1>
        <h2 className="fs-1 mb-3">
          {error?.status === 404 ? 'Page Not Found' : 
           error?.status === 500 ? 'Internal Server Error' : 
           'Unexpected Error'}
        </h2>
        <p className="lead mb-4">{errorMessage}</p>
        {error?.status !== 500 && (
          <Link to="/" className="btn btn-primary btn-lg">
            Go back to Homepage
          </Link>
        )}
      </div>
    </div>
    </div>
  );
};

export default ErrorPage;